import React from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import {
    Asset,
    AssetsProperties,
    AssetStatus,
    AssetType,
    AssociatedDevice,
    IdentificationType,
    SortDirection,
    Tag,
} from './types';
import { AssetsList } from './AssetsList';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
// eslint-disable-next-line import/no-named-as-default
import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import AssetsListDetailContainer from '../../containers/assets/details/AssetsListDetail.container';
import { orderBy } from 'natural-orderby';
import { pushAssetsLocation, UrlParamHelper } from '../componentsHelper';
import ReleaseInfoBannerContent from '../ReleaseInfoBannerContent';
import { DeviceType, SelectedTag, SelectedTagStatus } from './details/types';
import AssetsListToolbarContainer from '../../containers/assets/AssetsListToolbar.container';
import FilterOptionsDialogContainer from '../../containers/assets/FilterOptionsDialog.container';
import TagManagerWrapperContainer from '../../containers/assets/details/TagManagerWrapper.container';
import intersection from 'lodash/intersection';
import { closableSuccessNotification, nonVanishingErrorNotification } from './ClickableNotifications';
import { RouteComponentProps } from '../../utils/routerUtils';
import { Params } from 'react-router';
import { deviceTypeToDisplayedString } from './TelematicsName';
import { BulkArchiveAssetConfirmationDialog } from './BulkArchiveAssetConfirmationDialog';

export const COLUMNS_EXCLUDED_FROM_SORTING = ['tags', 'devices'];
export const ASSETS_PER_PAGE = 100;

export interface AssetsComponentState {
    unsavedAssetChanges: boolean;
    showConfirmDialog: boolean;
    discardChangesCallback: () => void;
    newRowId: string | null;
    selectedAssetIds: string[];
    tagManagerSelectedTags: SelectedTag[];
    showAddToGroupDialog: boolean;
    showRemoveFromGroupDialog: boolean;
    showBulkArchiveDialog: boolean;
    numberOfAssetsToDisplay: number;
    maxAssetsPerPage: number;
}

type AssetsProps = AssetsProperties & RouteComponentProps & WrappedComponentProps;

class Assets extends React.Component<AssetsProps, AssetsComponentState> {
    constructor(props: AssetsProps) {
        super(props);
        this.state = {
            unsavedAssetChanges: false,
            showConfirmDialog: false,
            newRowId: null,
            discardChangesCallback: () => {},
            selectedAssetIds: [],
            tagManagerSelectedTags: [],
            showAddToGroupDialog: false,
            showRemoveFromGroupDialog: false,
            showBulkArchiveDialog: false,
            numberOfAssetsToDisplay: ASSETS_PER_PAGE,
            maxAssetsPerPage: ASSETS_PER_PAGE,
        };
    }

    componentDidMount() {
        this.props.fetchTags(this.props.accountId);
        this.props.fetchAssets();
        this.props.fetchDevices();
        this.updateSortFromQueryParam();
        this.updateSearchValueFromQueryParam();
        this.applyAssetFiltersFromQueryParams();
        this.setState({
            ...this.state,
            discardChangesCallback: this.onSidebarChange,
        });

        if (this.hasShowDialogParameter()) {
            this.props.showCreateDialog();
        }
    }

    componentDidUpdate(
        prevProps: Readonly<AssetsProps>,
        prevState: Readonly<AssetsComponentState>,
        snapshot?: any
    ): void {
        // tags update notification logic
        if (
            this.props.assetTagLastSuccessfullyRemoved &&
            this.props.assetTagLastSuccessfullyRemoved !== prevProps.assetTagLastSuccessfullyRemoved
        ) {
            const tagName = this.props.assetTagLastSuccessfullyRemoved.name;
            closableSuccessNotification(
                <FormattedMessage id={'assets.assets.asset.tag.remove.success'} values={{ tagName }} />,
                'success-notification-tag-removed'
            );
        }

        if (
            this.props.assetTagLastFailedRemoved &&
            this.props.assetTagLastFailedRemoved !== prevProps.assetTagLastFailedRemoved
        ) {
            const tagName = this.props.assetTagLastFailedRemoved.name;
            nonVanishingErrorNotification(
                <FormattedMessage id={'assets.assets.asset.tag.remove.failure'} values={{ tagName }} />,
                'T01'
            );
        }

        if (
            this.props.assetTagLastSuccessfullyAdded &&
            this.props.assetTagLastSuccessfullyAdded !== prevProps.assetTagLastSuccessfullyAdded
        ) {
            //
            const tagName = this.props.assetTagLastSuccessfullyAdded.name;
            closableSuccessNotification(
                <FormattedMessage id={'assets.assets.asset.tag.add.success'} values={{ tagName }} />,
                'success-notification-tag-added'
            );
            this.props.resetAssetTagsUpdatingResetState();
        }

        if (
            this.props.assetTagLastFailedAdded &&
            this.props.assetTagLastFailedAdded !== prevProps.assetTagLastFailedAdded
        ) {
            const tagName = this.props.assetTagLastFailedAdded.name;
            nonVanishingErrorNotification(
                <FormattedMessage id={'assets.assets.asset.tag.add.failure'} values={{ tagName }} />,
                'T02'
            );
            this.props.resetAssetTagsUpdatingResetState();
        }

        const assetIdFromUrl = this.getAssetIdFromUrl();

        if (assetIdFromUrl !== this.props.selectedAsset?.id) {
            const selectedAsset = this.props.assets.find((asset) => asset.id === assetIdFromUrl) || null;
            this.props.selectAsset(selectedAsset);
        }
        if (
            this.queryParameterHasChanged(prevProps, prevState, 'q') &&
            this.props.searchString === prevProps.searchString
        ) {
            this.updateSearchValueFromQueryParam();
        }
        if (
            this.queryParameterHasChanged(prevProps, prevState, 'sort') &&
            this.props.sortBy === prevProps.sortBy &&
            this.props.sortDir === prevProps.sortDir
        ) {
            this.updateSortFromQueryParam();
        }
        if (
            this.queryParameterHasChanged(prevProps, prevState, 'withoutDataSource') &&
            this.props.showOnlyAssetsWithoutDataSource === prevProps.showOnlyAssetsWithoutDataSource
        ) {
            this.applyAssetFiltersFromQueryParams();
        }
        if (
            this.props.searchString !== prevProps.searchString ||
            this.props.showOnlyAssetsWithoutDataSource !== prevProps.showOnlyAssetsWithoutDataSource ||
            this.props.showActiveAssets !== prevProps.showActiveAssets ||
            this.props.showArchivedAssets !== prevProps.showArchivedAssets ||
            this.props.sortBy !== prevProps.sortBy ||
            this.props.sortDir !== prevProps.sortDir
        ) {
            this.updateQueryParams();
        }
        if (
            this.props.selectedAsset?.id === null &&
            prevProps.selectedAsset?.id !== null &&
            this.state.unsavedAssetChanges
        ) {
            this.setState({
                ...this.state,
                unsavedAssetChanges: false,
            });
        }
    }

    private hasShowDialogParameter(): boolean {
        return UrlParamHelper.parseQueryParams(this.props.location.search)['createAssetModal'] === 'show';
    }

    shouldComponentUpdate(
        nextProps: Readonly<AssetsProps>,
        nextState: Readonly<AssetsComponentState>,
        nextContext: any
    ): boolean {
        // prevent rerender of TagManagerWrapperContainer during tag selection
        return this.state.tagManagerSelectedTags === nextState.tagManagerSelectedTags;
    }

    updateSortFromQueryParam() {
        const parsedQuery = UrlParamHelper.parseQueryParams(this.props.location.search);
        const sortFromParam = parsedQuery.sort as string;
        if (sortFromParam === undefined) {
            return;
        }
        let sortDir: SortDirection;
        switch (sortFromParam[0]) {
            case ' ': // a plus in an url is interpreted as a space
                sortDir = SortDirection.ASCENDING;
                break;
            case '-':
                sortDir = SortDirection.DESCENDING;
                break;
            default:
                return;
        }
        const sortBy = sortFromParam.substring(1) as string;
        this.props.handleSortChange(sortBy, sortDir);
    }

    updateSearchValueFromQueryParam() {
        const parsedQuery = UrlParamHelper.parseQueryParams(this.props.location.search);
        let searchQueryFromParam = parsedQuery.q as string;
        if (searchQueryFromParam === undefined) {
            searchQueryFromParam = '';
        }
        this.props.handleSearchChange(searchQueryFromParam);
    }

    applyAssetFiltersFromQueryParams() {
        const parsedQuery = UrlParamHelper.parseQueryParams(this.props.location.search);
        const hideActiveAssets = parsedQuery.status === 'archived' || parsedQuery.status === 'none';
        const hideArchivedAssets = parsedQuery.status === 'active' || parsedQuery.status === 'none';
        switch (parsedQuery.withoutDataSource) {
            case 'true':
                this.props.applyAssetFilterChanges(true, !hideActiveAssets, !hideArchivedAssets);
                break;
            case 'false':
                this.props.applyAssetFilterChanges(false, !hideActiveAssets, !hideArchivedAssets);
                break;
            default:
                this.props.applyAssetFilterChanges(false, !hideActiveAssets, !hideArchivedAssets);
                return;
        }
    }

    queryParameterHasChanged(prevProps: Readonly<AssetsProps>, prevState: Readonly<AssetsComponentState>, key: string) {
        return (
            UrlParamHelper.parseQueryParams(this.props.location.search)[key] !==
            UrlParamHelper.parseQueryParams(prevProps.location.search)[key]
        );
    }

    toggleAssetIdSelection = (assetId: string) => {
        const newSelectedAssetIds = this.state.selectedAssetIds.includes(assetId)
            ? this.state.selectedAssetIds.filter((selectedAssetId) => selectedAssetId !== assetId)
            : this.state.selectedAssetIds.concat(assetId);

        this.setState({ ...this.state, selectedAssetIds: newSelectedAssetIds });
    };

    isTargetCheckbox = (event: React.MouseEvent) => {
        const target = event.target as Element;
        const classlistValue = target.classList.value;
        return classlistValue === 'checkbox-text' || classlistValue === 'checkbox';
    };

    handleRowClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const rowId = event.currentTarget.getAttribute('data-key');
        if (this.state.unsavedAssetChanges) {
            this.toggleConfirmDialog(true, rowId);
        } else {
            if (this.isTargetCheckbox(event) && rowId != null) {
                this.toggleAssetIdSelection(rowId);
            } else if (rowId && rowId !== this.props.selectedAsset?.id) {
                pushAssetsLocation(this.props.push, this.props.location.search, rowId);
            } else {
                pushAssetsLocation(this.props.push, this.props.location.search, null);
            }
        }
    };

    onSidebarChange = () => {
        this.setState({
            ...this.state,
            unsavedAssetChanges: false,
            showConfirmDialog: false,
            newRowId: null,
        });
        pushAssetsLocation(this.props.push, this.props.location.search, this.state.newRowId);
    };

    getAssetIdFromUrl() {
        const routerParams: Readonly<Params> = this.props.params;

        // eslint-disable-next-line no-prototype-builtins
        return routerParams.hasOwnProperty('id') ? routerParams.id : null;
    }

    toggleConfirmDialog = (
        opened: boolean,
        newRowId: string | null = null,
        discardChangesCallback: () => void = this.onSidebarChange
    ) => {
        this.setState({ ...this.state, showConfirmDialog: opened, newRowId, discardChangesCallback });
    };

    toggleUnsavedAssetChanges = (unsavedChanges: boolean) => {
        this.setState({ ...this.state, unsavedAssetChanges: unsavedChanges });
    };

    updateQueryParams = () => {
        const urlParamHelper = new UrlParamHelper(this.props.push, this.props.location.search);
        urlParamHelper.replaceQueryParam('q', this.props.searchString);
        if (this.props.sortBy) {
            urlParamHelper.replaceQueryParam(
                'sort',
                `${this.props.sortDir === SortDirection.ASCENDING ? '+' : '-'}${this.props.sortBy}`
            );
        }
        urlParamHelper.replaceQueryParam(
            'withoutDataSource',
            this.props.showOnlyAssetsWithoutDataSource ? `${this.props.showOnlyAssetsWithoutDataSource}` : null
        );
        if (this.props.showActiveAssets && this.props.showArchivedAssets) {
            urlParamHelper.replaceQueryParam('status', 'all');
        } else if (this.props.showActiveAssets) {
            urlParamHelper.replaceQueryParam('status', 'active');
        } else if (this.props.showArchivedAssets) {
            urlParamHelper.replaceQueryParam('status', 'archived');
        } else {
            urlParamHelper.replaceQueryParam('status', 'none');
        }
        urlParamHelper.dispatchQueryIfChanged();
    };

    handleSortChange = (column: string, sortDir: SortDirection) => {
        if (!COLUMNS_EXCLUDED_FROM_SORTING.includes(column)) {
            this.props.handleSortChange(column, sortDir);
        }
    };

    getSortedAssets = (assets: Asset[]) => {
        if (this.props.sortBy) {
            return orderBy(assets, [this.props.sortBy], [this.props.sortDir]);
        } else {
            return assets;
        }
    };

    getDevicesForAsset = (assetId: string): AssociatedDevice[] => {
        const devices = this.props.devices[assetId];
        return devices === undefined ? [] : devices;
    };

    getFilteredAssets = () => {
        const assetsFilteredBySearchString = this.filterBySearchValue(this.props.assets);
        return this.filteredByFilterOptions(assetsFilteredBySearchString);
    };

    filterBySearchValue = (assets: Asset[]): Asset[] => {
        const searchValue = this.props.searchString.toLocaleLowerCase();
        if (searchValue) {
            return searchValue.split(' ').reduce(
                (prevResult, searchString) =>
                    prevResult.filter(
                        (asset) =>
                            asset.name.toLowerCase().includes(searchString) ||
                            (asset.identification && asset.identification.toLowerCase().includes(searchString)) ||
                            (asset.identification_type &&
                                this.getFormattedMessageAssetIdentificationType(asset.identification_type).includes(
                                    searchString
                                )) ||
                            (asset.brand && asset.brand.toLowerCase().includes(searchString)) ||
                            (asset.type && this.getFormattedMessageAssetType(asset.type).includes(searchString)) ||
                            (asset.license_plate && asset.license_plate.toLowerCase().includes(searchString)) ||
                            this.props.availableTags
                                .filter((tag) => asset.tags.includes(tag.id))
                                .some((tag) => tag.name.toLowerCase().includes(searchString)) ||
                            this.getDevicesForAsset(asset.id).some(
                                (associatedDevice) =>
                                    associatedDevice.device.identification.toLowerCase() === searchString.toLowerCase()
                            ) ||
                            this.getDevicesForAsset(asset.id)
                                .map((associatedDevice) =>
                                    this.getFormattedMessageDeviceType(associatedDevice.device.type)
                                )
                                .some((deviceType) => deviceType.includes(searchString))
                    ),
                assets
            );
        } else {
            return assets;
        }
    };

    filteredByFilterOptions = (assets: Asset[]): Asset[] => {
        return assets
            .filter((asset) =>
                this.props.showOnlyAssetsWithoutDataSource ? this.getDevicesForAsset(asset.id).length === 0 : true
            )
            .filter((asset) => (!this.props.showActiveAssets ? asset.status !== AssetStatus.active : true))
            .filter((asset) => (!this.props.showArchivedAssets ? asset.status !== AssetStatus.archived : true));
    };

    getFormattedMessageAssetStatus(assetStatus: AssetStatus): string {
        return this.props.intl
            .formatMessage({ id: `assets.assets.asset.status.${assetStatus.toString()}` })
            .toLowerCase();
    }

    getFormattedMessageAssetIdentificationType(identificationType: IdentificationType): string {
        return this.props.intl
            .formatMessage({ id: `assets.assets.asset.identification_type.${identificationType.toString()}` })
            .toLowerCase();
    }

    getFormattedMessageAssetType(assetType: AssetType): string {
        return this.props.intl.formatMessage({ id: `assets.assets.asset.type.${assetType.toString()}` }).toLowerCase();
    }

    getFormattedMessageDeviceType(deviceType: DeviceType): string {
        return deviceTypeToDisplayedString(deviceType);
    }

    handleToggleAll = (shouldSelect: boolean) => {
        const allDisplayedRowIds = this.getFilteredAssets().map((asset) => asset.id);

        if (shouldSelect) {
            this.setState({ ...this.state, selectedAssetIds: allDisplayedRowIds });
        } else {
            this.setState({ ...this.state, selectedAssetIds: [] });
        }
    };

    onTagListChange = (selectedTags: SelectedTag[]) => {
        this.setState(() => ({
            tagManagerSelectedTags: selectedTags.filter((tag) => tag.status !== SelectedTagStatus.unchanged),
        }));
    };

    private async createAndUpdateAssetTagsFromSelection() {
        await this.createNewTags(this.state.tagManagerSelectedTags);
        const selectedTags = this.state.tagManagerSelectedTags;
        const selectedAssets = this.props.assets.filter(
            (asset) => this.state.selectedAssetIds.includes(asset.id) && this.assetNeedsTagUpdate(selectedTags, asset)
        );
        selectedAssets.forEach((asset) => {
            this.updateAssetTag(asset, this.state.tagManagerSelectedTags);
        });
    }

    private assetNeedsTagUpdate(selectedTags: SelectedTag[], asset: Asset) {
        return selectedTags.some((assetTag) => {
            if (assetTag.status === SelectedTagStatus.added) {
                return !asset.tags.includes(assetTag.id);
            } else if (assetTag.status === SelectedTagStatus.removed) {
                return asset.tags.includes(assetTag.id);
            } else {
                return true;
            }
        });
    }

    private async createNewTags(tagsToUpdate: SelectedTag[]) {
        const newTags = tagsToUpdate.filter((currentTag) => currentTag.status === SelectedTagStatus.created);
        await this.props.createAssetTags(newTags);
    }

    private async updateAssetTag(asset: Asset, tagsToUpdate: SelectedTag[]) {
        await this.props.updateAssetTags(asset, tagsToUpdate);
    }

    showAddToGroupDialog = (show: boolean) => {
        this.setState({ ...this.state, showAddToGroupDialog: show });
    };

    showBulkArchiveDialog = (show: boolean) => {
        this.setState({ ...this.state, showBulkArchiveDialog: show });
    };

    showRemoveFromGroupDialog = (show: boolean) => {
        this.setState({ ...this.state, showRemoveFromGroupDialog: show });
    };

    private getAddToGroupDialogAvailableTags = () => {
        const selectedTagsIntersection = intersection(
            ...this.props.assets
                .filter((asset) => {
                    return this.state.selectedAssetIds.includes(asset.id);
                })
                .map((asset) => asset.tags)
        );
        return this.props.availableTags.filter((tag) => !selectedTagsIntersection.includes(tag.id));
    };

    private getRemoveFromGroupDialogAvailableTags = () => {
        return this.props.availableTags.filter((tag) => this.getRemoveFromGroupDialogAssetTags().includes(tag.id));
    };

    private getRemoveFromGroupDialogAssetTags = () => {
        return this.props.assets
            .filter((asset) => this.state.selectedAssetIds.includes(asset.id))
            .flatMap((asset) => asset.tags);
    };

    private onClickAddToGroupDialogConfirm = () => {
        this.setState({ showAddToGroupDialog: false });
        this.createAndUpdateAssetTagsFromSelection();
    };

    private onClickRemoveFromGroupDialogConfirm = () => {
        this.setState({ showRemoveFromGroupDialog: false });
        this.createAndUpdateAssetTagsFromSelection();
    };

    private getTagManagerWrapperContainer(
        availableTags: Tag[],
        assetTags: string[] | undefined,
        useCustomTags: boolean
    ) {
        return (
            <TagManagerWrapperContainer
                accountId={this.props.accountId}
                assetTags={assetTags}
                onTagListChange={this.onTagListChange}
                availableTags={availableTags}
                useCustomTags={useCustomTags}
                customMessage={
                    useCustomTags
                        ? 'assets.assets.tag-manager.create-add-message'
                        : 'assets.assets.tag-manager.remove-message'
                }
            />
        );
    }

    private handleLoadMore = () => {
        this.setState((state) => ({
            ...state,
            numberOfAssetsToDisplay: state.numberOfAssetsToDisplay + this.state.maxAssetsPerPage,
        }));
    };

    private readonly isFeatureBannerEnabled = false;

    render() {
        const { showConfirmDialog } = this.state;
        const handleSortChange = (column: string) => {
            if (column === this.props.sortBy) {
                const sortDir =
                    this.props.sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
                this.handleSortChange(column, sortDir);
            } else {
                this.handleSortChange(column, SortDirection.ASCENDING);
            }
        };

        const sortedAssets = this.getSortedAssets(this.getFilteredAssets());
        const assetsToRender = sortedAssets.slice(0, this.state.numberOfAssetsToDisplay);
        const selectedAsset = this.props.selectedAsset;
        return (
            <React.Fragment>
                {showConfirmDialog && (
                    <ConfirmationDialog
                        show={showConfirmDialog}
                        title={<FormattedMessage id={'assets.assets.confirmation-dialog.title'} />}
                        content={<FormattedMessage id={'assets.assets.confirmation-dialog.content'} />}
                        onClickConfirm={this.state.discardChangesCallback}
                        onClickCancel={() => {
                            this.toggleConfirmDialog(false);
                        }}
                        cancelButtonText={<FormattedMessage id={'assets.assets.confirmation-dialog.button.cancel'} />}
                        confirmButtonText={<FormattedMessage id={'assets.assets.confirmation-dialog.button.confirm'} />}
                        disableConfirm={false}
                        useOverflow={false}
                        bsSize={'sm'}
                    />
                )}
                {this.state.showAddToGroupDialog && (
                    <ConfirmationDialog
                        show={this.state.showAddToGroupDialog}
                        title={<FormattedMessage id={'assets.assets.addToGroup'} />}
                        content={this.getTagManagerWrapperContainer(this.getAddToGroupDialogAvailableTags(), [], true)}
                        bsSize={'sm'}
                        onClickConfirm={this.onClickAddToGroupDialogConfirm}
                        onClickCancel={() => this.setState({ showAddToGroupDialog: false })}
                        cancelButtonText={<FormattedMessage id={'assets.assets.add-to-group-dialog.button.cancel'} />}
                        confirmButtonText={
                            <div data-cy={'confirmation-dialog-confirm-button'}>
                                <FormattedMessage id={'assets.assets.add-to-group-dialog.button.confirm'} />
                            </div>
                        }
                        useOverflow={false}
                    />
                )}
                {this.state.showRemoveFromGroupDialog && (
                    <ConfirmationDialog
                        show={this.state.showRemoveFromGroupDialog}
                        title={<FormattedMessage id={'assets.assets.removeFromGroup'} />}
                        content={this.getTagManagerWrapperContainer(
                            this.getRemoveFromGroupDialogAvailableTags(),
                            this.getRemoveFromGroupDialogAssetTags(),
                            false
                        )}
                        bsSize={'sm'}
                        onClickConfirm={this.onClickRemoveFromGroupDialogConfirm}
                        onClickCancel={() => this.setState({ showRemoveFromGroupDialog: false })}
                        cancelButtonText={<FormattedMessage id={'assets.assets.add-to-group-dialog.button.cancel'} />}
                        confirmButtonText={
                            <div data-cy={'confirmation-dialog-confirm-button'}>
                                <FormattedMessage id={'assets.assets.add-to-group-dialog.button.confirm'} />
                            </div>
                        }
                        useOverflow={false}
                    />
                )}
                {this.state.showBulkArchiveDialog && (
                    <BulkArchiveAssetConfirmationDialog
                        show={this.state.showBulkArchiveDialog}
                        assets={this.props.assets.filter((asset) => this.state.selectedAssetIds.includes(asset.id))}
                        hideBulkArchiveAssetDialog={() => {
                            this.showBulkArchiveDialog(false);
                        }}
                    />
                )}
                <ApplicationLayout.Sidebar className="right">
                    {selectedAsset && (
                        <AssetsListDetailContainer
                            asset={selectedAsset}
                            onClose={this.onSidebarChange}
                            toggleConfirmDialog={this.toggleConfirmDialog}
                            discardChangesCallbackToCloseSidebar={this.onSidebarChange}
                            toggleUnsavedAssetChanges={this.toggleUnsavedAssetChanges}
                        />
                    )}
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body
                    banner={
                        this.isFeatureBannerEnabled && (
                            <ApplicationLayoutBodyBanner>
                                <ReleaseInfoBannerContent />
                            </ApplicationLayoutBodyBanner>
                        )
                    }
                >
                    {this.props.fetching ? (
                        <Spinner text={<FormattedMessage id={'assets.spinner.loading'} />} />
                    ) : (
                        <React.Fragment>
                            <AssetsListToolbarContainer
                                assets={sortedAssets}
                                filterOptionsDialog={<FilterOptionsDialogContainer />}
                            />
                            <AssetsList
                                assets={assetsToRender}
                                devices={this.props.devices}
                                selectedAsset={this.props.selectedAsset}
                                tableViewType={this.props.tableViewType}
                                handleRowClick={this.handleRowClick}
                                tags={this.props.availableTags}
                                handleSortChange={handleSortChange}
                                sortBy={this.props.sortBy}
                                sortDir={this.props.sortDir}
                                handleToggleAll={this.handleToggleAll}
                                selectedAssetIds={this.state.selectedAssetIds}
                                showAddToGroupDialog={this.showAddToGroupDialog}
                                showBulkArchiveDialog={this.showBulkArchiveDialog}
                                showRemoveFromGroupDialog={this.showRemoveFromGroupDialog}
                            />
                            {assetsToRender.length > 0 && (
                                <LoadMoreButton
                                    data-test-id={'loadMoreButton'}
                                    loaded={assetsToRender.length}
                                    total={sortedAssets.length}
                                    onLoadMore={this.handleLoadMore}
                                    loadMoreMessage={<FormattedMessage id={'assets.assets.loadMore'} />}
                                    noMoreMessage={<FormattedMessage id={'assets.assets.noMore'} />}
                                />
                            )}
                        </React.Fragment>
                    )}
                </ApplicationLayout.Body>
            </React.Fragment>
        );
    }
}

export default Assets;
