import React from 'react';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { AssetDevicesProps } from './types';
import TelematicsName from './TelematicsName';

export default class AssetDevices extends React.Component<AssetDevicesProps, {}> {
    render() {
        return (
            <ul className="list-inline margin-bottom-0">
                {this.props.assetDevices.map((associatedDevice) => {
                    return (
                        <li key={associatedDevice.device.id} className="margin-1">
                            <Tag size={'small'}>
                                <TelematicsName deviceType={associatedDevice.device.type} />{' '}
                            </Tag>
                        </li>
                    );
                })}
            </ul>
        );
    }
}
