import { DeviceType } from '../../details/types';
import { allBrandsWithType, BRAND_TILES } from './brandTileConfig';
import { allTypesAndBrands, ASSET_TYPE_TILES } from './assetTypeTileConfig';
import { config } from '../../../../../../config';
import { TelematicsTile } from './types';
import { GROUP_TILES } from './groupTilesConfig';

// Overview over (some) RIO connectors
// https://collaboration.msi.audi.com/confluence/pages/viewpage.action?spaceKey=TICON&title=Inbound+Connectors

export const TELEMATICS_TILES: Record<
    | 'daimlerFleetboard'
    | 'rioBox'
    | 'rioPocketDriver'
    | 'rioPocketDriverTest'
    | 'rio4DriversApp'
    | 'scaniaTelematics'
    | 'schmitzCargobull'
    | 'linkBox'
    | 'webFleet'
    | 'webFleetRetrofit'
    | 'manOemTelematicsVan'
    | 'manOemTelematicsTruckBus'
    | 'kroneTelematics'
    | 'volvoTelematics'
    | 'idemTelematics'
    | 'koegel'
    | 'schwarzmueller'
    | 'timocom'
    | 'noTelematics'
    | 'renault',
    TelematicsTile
> = {
    // Provided by MAN Core Connectivity
    // RIOTT-6600: from now on, only when selecting the RIO Box telematic, we will be selecting a TBM3 device indeed,
    // besides the fact that both TBM3 and CM4 devices will follow the same flow in the end.
    rioBox: {
        displayName: 'RIO Box',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.rio-box',
        deviceType: DeviceType.TBM3,
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        showForNonEu: true,
        suitableFor: [
            ...allBrandsWithType(ASSET_TYPE_TILES.truck),
            ...allBrandsWithType(ASSET_TYPE_TILES.bus),
            { type: ASSET_TYPE_TILES.van, brand: BRAND_TILES.man },
        ],
        oemFor: [],
        group: GROUP_TILES.rioTelematics,
    },

    daimlerFleetboard: {
        displayName: 'Fleetboard',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.fleetboard',
        deviceType: DeviceType.FLEETBOARD,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/fleetboard`,
        tileType: 'default',
        suitableFor: [
            { type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.daimler },
            { type: ASSET_TYPE_TILES.bus, brand: BRAND_TILES.daimler },
        ],
        oemFor: [
            { type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.daimler },
            { type: ASSET_TYPE_TILES.bus, brand: BRAND_TILES.daimler },
        ],
    },

    // Provided by MAN Core Connectivity only for MAN eTGE vans
    linkBox: {
        displayName: 'LINK Box',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.link-box',
        deviceType: DeviceType.MAN_WEBFLEET,
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.van, brand: BRAND_TILES.man }],
        oemFor: [],
        group: GROUP_TILES.other,
    },

    // Provided by transport x. It syncs all data from Webfleet to RIO
    webFleet: {
        displayName: 'Webfleet',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.web-fleet',
        deviceType: DeviceType.WEBFLEET,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/webfleet`,
        tileType: 'default',
        suitableFor: allTypesAndBrands(),
        oemFor: [],
        group: GROUP_TILES.other,
    },

    webFleetRetrofit: {
        displayName: 'Webfleet RIO Edition',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.web-fleet-retrofit',
        deviceType: DeviceType.WEBFLEET_RETROFIT,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/webfleet-retrofit`,
        tileType: 'default',
        suitableFor: allTypesAndBrands(),
        featureToggleName: 'webfleetRetrofit',
        oemFor: [],
        group: GROUP_TILES.other,
    },

    // Provided by MAN Core Connectivity only for MAN TGE vans
    manOemTelematicsVan: {
        displayName: 'MAN Telematics',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.man-telematic',
        deviceType: DeviceType.OCU3,
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.van, brand: BRAND_TILES.man }],
        oemFor: [{ type: ASSET_TYPE_TILES.van, brand: BRAND_TILES.man }],
    },

    // RIOTT-6600: from now on, only when selecting the MAN OEM telematic, we will be selecting a CM4 device indeed,
    // besides the fact that both TBM3 and CM4 devices will follow the same flow in the end.
    manOemTelematicsTruckBus: {
        displayName: 'MAN Telematics',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.man-oem-telematic-truck-bus',
        deviceType: DeviceType.CM4,
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        suitableFor: [
            { type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.man },
            { type: ASSET_TYPE_TILES.bus, brand: BRAND_TILES.man },
        ],
        oemFor: [
            { type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.man },
            { type: ASSET_TYPE_TILES.bus, brand: BRAND_TILES.man },
        ],
    },

    // The new mobile app to provide position data
    rioPocketDriver: {
        displayName: 'Pocket Driver',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.rio-pocket-driver',
        deviceType: DeviceType.POCKET_DRIVER_APP,
        //https://connector.assets.rio.cloud
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        suitableFor: allBrandsWithType(ASSET_TYPE_TILES.truck, ASSET_TYPE_TILES.van, ASSET_TYPE_TILES.bus),
        oemFor: [],
        group: GROUP_TILES.rioTelematics,
    },
    // The new mobile app to provide position data
    rioPocketDriverTest: {
        displayName: 'Pocket Driver Test',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.rio-pocket-driver',
        deviceType: DeviceType.POCKET_DRIVER_APP,
        //https://connector.assets.rio.cloud
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        suitableFor: allBrandsWithType(ASSET_TYPE_TILES.truck, ASSET_TYPE_TILES.van, ASSET_TYPE_TILES.bus),
        oemFor: [],
        group: GROUP_TILES.rioTelematics,
        featureToggleName: 'rioPocketDriverTest',
    },
    // The old legacy mobile app to provide position data
    rio4DriversApp: {
        displayName: 'RIO4Drivers App',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.rio-4-drivers',
        deviceType: DeviceType.TRUCK_LOGIN_DEVICE,
        // https://connector.assets.rio.cloud
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'default',
        suitableFor: allBrandsWithType(ASSET_TYPE_TILES.truck, ASSET_TYPE_TILES.van, ASSET_TYPE_TILES.bus),
        oemFor: [],
        group: GROUP_TILES.rioTelematics,
        featureToggleName: 'rio4DriversApp',
    },
    // Provided by transport X, Syncs Scania vehicles to RIO
    scaniaTelematics: {
        displayName: 'Scania Telematics',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.scania-telematic',
        deviceType: DeviceType.SCANIA_TELEMATICS,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/scania`,
        tileType: 'default',
        suitableFor: [
            { type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.scania },
            { type: ASSET_TYPE_TILES.bus, brand: BRAND_TILES.scania },
        ],
        oemFor: [
            { type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.scania },
            { type: ASSET_TYPE_TILES.bus, brand: BRAND_TILES.scania },
        ],
    },
    kroneTelematics: {
        displayName: 'Krone',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.krone',
        deviceType: DeviceType.KRONE,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/krone`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.krone }],
        oemFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.krone }],
        featureToggleName: 'krone',
    },
    schmitzCargobull: {
        displayName: 'Schmitz Cargobull',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.schmitz-cargobull',
        deviceType: DeviceType.SCHMITZ_CARGOBULL,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/cargobull`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.schmitzCargobull }],
        oemFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.schmitzCargobull }],
    },
    volvoTelematics: {
        displayName: 'Volvo',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.volvo',
        deviceType: DeviceType.VOLVO_TELEMATICS,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/volvo`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.volvo }],
        oemFor: [{ type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.volvo }],
    },
    idemTelematics: {
        displayName: 'Idem Telematics',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.idem',
        deviceType: DeviceType.IDEM_TELEMATICS,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/idem`,
        tileType: 'default',
        suitableFor: allBrandsWithType(ASSET_TYPE_TILES.trailer),
        oemFor: [],
        group: GROUP_TILES.other,
        featureToggleName: 'idem',
    },
    koegel: {
        displayName: 'Kögel Telematics',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.koegel',
        deviceType: DeviceType.KOEGEL_TELEMATICS,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/koegel`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.koegel }],
        oemFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.koegel }],
        featureToggleName: 'koegel',
    },
    schwarzmueller: {
        displayName: 'SWiT',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.schwarzmueller',
        deviceType: DeviceType.SCHWARZMUELLER_TELEMATICS,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/schwarzmueller`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.schwarzmueller }],
        oemFor: [{ type: ASSET_TYPE_TILES.trailer, brand: BRAND_TILES.schwarzmueller }],
        featureToggleName: 'schwarzmueller',
    },
    timocom: {
        displayName: 'Timocom',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.timocom',
        deviceType: DeviceType.TIMOCOM,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/timocom`,
        tileType: 'default',
        suitableFor: allBrandsWithType(ASSET_TYPE_TILES.truck),
        oemFor: [],
        group: GROUP_TILES.other,
        featureToggleName: 'timocom',
    },
    renault: {
        displayName: 'Renault',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.renault',
        deviceType: DeviceType.RENAULT,
        connectorUri: `${config.external.iframe.easyOnboarding}/#/connect/renault`,
        tileType: 'default',
        suitableFor: [{ type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.renaultTrucks }],
        oemFor: [{ type: ASSET_TYPE_TILES.truck, brand: BRAND_TILES.renaultTrucks }],
    },
    noTelematics: {
        displayName: 'assets.assets.create-new-dialog.telematics.tiles.display-name.no-telematic',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.no-telematic',
        deviceType: DeviceType.NONE,
        connectorUri: `${config.external.iframe.assetConnector}/#/connect`,
        tileType: 'decent',
        showForNonEu: true,
        suitableFor: allTypesAndBrands(),
        oemFor: [],
    },
};

export const ALL_TELEMATICS_TILES = Object.values(TELEMATICS_TILES).filter(
    (tile) => tile !== TELEMATICS_TILES.noTelematics
);

export const TELEMATICS_TILES_DISPLAYED_FIRST = [TELEMATICS_TILES.rioBox, TELEMATICS_TILES.rioPocketDriver];
