import React from 'react';
import { CSVLink } from 'react-csv';
import { getDataForCsvLink } from './CsvExport.helper';
import { CsvExportOwnProps, CsvExportPropertiesFromState } from '../../containers/assets/CsvExport.container';
import { WrappedComponentProps } from 'react-intl';

type CsvExportProperties = CsvExportOwnProps & CsvExportPropertiesFromState & WrappedComponentProps;

export default class CsvExport extends React.Component<CsvExportProperties, {}> {
    render() {
        const headers = [
            { label: this.getFormattedMessage('name'), key: 'name' },
            { label: 'AssetId', key: 'assetId' },
            { label: this.getFormattedMessage('identification'), key: 'identification' },
            { label: this.getFormattedMessage('license-plate'), key: 'license_plate' },
            { label: this.getFormattedMessage('brand'), key: 'brand' },
            { label: this.getFormattedMessage('type'), key: 'type' },
            { label: this.getFormattedMessage('tags'), key: 'tags' },
            { label: this.getFormattedMessage('devices'), key: 'devices' },
        ];

        const data = getDataForCsvLink(this.props.assets, this.props.availableTags, this.props.devices);

        return (
            <CSVLink
                className="btn btn-primary btn-icon-only"
                filename={'RIO-Assets.csv'}
                data={data}
                headers={headers}
            >
                <span className={'rioglyph rioglyph-download'} />
            </CSVLink>
        );
    }

    getFormattedMessage(label: string): string {
        return this.props.intl.formatMessage({ id: `assets.assets.asset.${label}` });
    }
}
