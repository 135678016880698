import { FormStep, FormStepNavigation } from './EasyOnboardingDialog';
import { TELEMATICS_TILES } from './configuration/telematicsTileConfig';
import { FormattedMessage } from 'react-intl';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../../utils/googleAnalytics4AssetOnboardingFlow';
import { BrandTile, GroupTile, TelematicsTile } from './configuration/types';
import { GROUP_TILES } from './configuration/groupTilesConfig';

interface DialogFooterProps {
    formStep: FormStep;
    handleFormStepNavigation: (goTo: FormStepNavigation) => void;
    setSelectedTelematics: (value: TelematicsTile | undefined) => void;
    selectedTelematics: TelematicsTile | undefined;
    setSelectedBrand: (value: BrandTile | undefined) => void;
    selectedBrand: BrandTile | undefined;
    selectedAssetId: string | undefined;
    selectedGroup: GroupTile | undefined;
    disableFooter?: boolean;
}
//Todo find a way to adapt to selected language when new contact form is in place
const CONTACT_FORMULAR_URL = 'https://rio.cloud/de/contact/form?opener=request-for-new-telematic';
export const DialogFooter = (props: DialogFooterProps) => {
    const showPrevButton =
        props.formStep === FormStep.selectBrand ||
        (props.formStep === FormStep.selectTelematics && props.selectedAssetId === undefined);
    const showSkipBrandSelectionButton = props.formStep === FormStep.selectBrand;
    const showSupportButton =
        (props.selectedBrand === undefined || props.selectedGroup === GROUP_TILES.other) &&
        props.formStep == FormStep.selectTelematics;
    const showNoTelematicsButton = props.formStep === FormStep.selectTelematics;
    const { dataLayerPush } = useGoogleAnalytics();
    const footerContent = (
        <div className="display-flex justify-content-between padding-20 bg-white border border-top-only border-color-lighter rounded-bottom">
            {showPrevButton ? (
                <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => {
                        props.handleFormStepNavigation(FormStepNavigation.prev);
                    }}
                >
                    <span className={'rioglyph rioglyph-chevron-left'} />
                    <FormattedMessage id="assets.assets.create.back" />
                </button>
            ) : (
                <span />
            )}
            {showSkipBrandSelectionButton && (
                <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => {
                        props.setSelectedBrand(undefined);
                        props.handleFormStepNavigation(FormStepNavigation.next);
                    }}
                    data-cy="skip-brand-selection-button"
                >
                    <FormattedMessage id="assets.assets.create-new-dialog.directly-to-telematics" />
                </button>
            )}
            {showSupportButton && (
                <button className="btn btn-default" type="button" onClick={() => window.open(CONTACT_FORMULAR_URL)}>
                    <div className="padding-10">
                        <FormattedMessage id="assets.assets.create-new-dialog.missing-telematic" />
                    </div>
                    <span className="rioglyph rioglyph-support"></span>
                </button>
            )}
            {showNoTelematicsButton && (
                <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => {
                        dataLayerPush({
                            trigger: GAEventTrigger.click,
                            event: GAEventName.selectTelematics,
                            element_name: 'no-telematics',
                            flow_name: GAFlowName.easyOnboardingFlow,
                        });
                        props.setSelectedTelematics(TELEMATICS_TILES.noTelematics);
                        props.handleFormStepNavigation(FormStepNavigation.next);
                    }}
                >
                    <FormattedMessage id="assets.assets.create-new-dialog.no-telematics" />
                </button>
            )}
        </div>
    );
    return !props.disableFooter && (showPrevButton || showSkipBrandSelectionButton) ? footerContent : null;
};
