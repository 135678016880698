import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders } from './utils';

interface ContractsResponse {
    contracts: Contract[];
}

export interface Contract {
    resource_id: string;
    resource_type: string;
    product_id: string;
    level: string;
    state: string;
    product_name: string;
    product_level_name: string;
    product_variant_name: string;
    starts_at: string;
    ends_at: string;
    activation_state: string;
}

const filterFreeTrialContracts = (contracts: Contract[]) =>
    contracts.filter((contract) => !contract.level.endsWith('-ft'));

export const contractsApi = createApi({
    reducerPath: 'contractsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.marketplace, prepareHeaders }),
    endpoints: (build) => ({
        getActiveContracts: build.query<Contract[], string>({
            query: (assetId: string | undefined) => ({
                url: `/contracts?resource_id=${assetId}`,
            }),
            transformResponse: (rawResult, meta, assetId) => {
                return filterFreeTrialContracts((rawResult as ContractsResponse).contracts);
            },
        }),
        bulkGetActiveContracts: build.query<(Contract & { id: string })[], string[]>({
            async queryFn(assetIds, _queryApi, _extraOptions, fetchWithBQ) {
                const contractsForAllAssets: (Contract & { id: string })[] = [];
                for (const assetId of assetIds) {
                    const result = await fetchWithBQ(`/contracts?resource_id=${assetId}`);
                    if (result.error) {
                        throw result.error;
                    }
                    const data = result.data as ContractsResponse;
                    const contractsForSingleAsset = filterFreeTrialContracts(data.contracts);
                    contractsForAllAssets.push(...contractsForSingleAsset.map((it) => ({ ...it, id: assetId })));
                }
                return { data: contractsForAllAssets };
            },
        }),
    }),
});

export const { useGetActiveContractsQuery, useBulkGetActiveContractsQuery } = contractsApi;
