import { allTypesAndBrands, ASSET_TYPE_TILES } from './assetTypeTileConfig';
import { GroupTile } from './types';
import { allBrandsWithType } from './brandTileConfig';

export const GROUP_TILES: Record<'rioTelematics' | 'other', GroupTile> = {
    rioTelematics: {
        name: 'rio',
        displayName: 'RIO',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.rio-telematics-group',
        showForNonEu: false,
        tileType: 'default',
        eligibleFor: allBrandsWithType(ASSET_TYPE_TILES.truck, ASSET_TYPE_TILES.bus, ASSET_TYPE_TILES.van),
    },
    other: {
        name: 'other',
        displayName: 'assets.assets.create-new-dialog.telematics.tiles.display-name.other-group',
        description: 'assets.assets.create-new-dialog.telematics.tiles.description.other-group',
        showForNonEu: true,
        tileType: 'default',
        eligibleFor: allTypesAndBrands(),
    },
};

export const GROUP_TILES_ORDER = ['rioTelematics', 'other'] as const;
export const ALL_GROUP_TILES = GROUP_TILES_ORDER.map((key) => GROUP_TILES[key]);
