import SelectionTile from './SelectionTile';
import orderBy from 'lodash/orderBy';
import { ALL_TELEMATICS_TILES, TELEMATICS_TILES_DISPLAYED_FIRST } from './configuration/telematicsTileConfig';
import { FeatureToggles, useToggle } from '../../../../../configuration/featureToggle/toggleHooks';
import { FormStepNavigation } from './EasyOnboardingDialog';
import { Fragment, FunctionComponent } from 'react';
import { useAppSelector } from '../../../../../configuration/setup/hooks';
import { getIsEuTenant } from '../../../../../configuration/login/loginSlice';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../../utils/googleAnalytics4AssetOnboardingFlow';
import { ALL_GROUP_TILES, GROUP_TILES } from './configuration/groupTilesConfig';
import { AssetTypeTile, BrandTile, GroupTile, TelematicsTile, typeBrandCombination } from './configuration/types';

interface FormStepSelectTelematicsProps {
    selectedAssetId: string | undefined;
    selectedBrand: BrandTile | undefined;
    selectedAssetType: AssetTypeTile | undefined;
    setSelectedTelematic: (value: TelematicsTile | undefined) => void;
    selectedGroup: GroupTile | undefined;
    setSelectedGroup: (value: GroupTile | undefined) => void;
    handleFormStepNavigation: (goto: FormStepNavigation) => void;
}

export const FormStepSelectTelematics: FunctionComponent<FormStepSelectTelematicsProps> = (props) => {
    const {
        selectedBrand,
        selectedAssetType,
        selectedGroup,
        selectedAssetId,
        setSelectedTelematic,
        setSelectedGroup,
        handleFormStepNavigation,
    } = props;
    const isEuTenant = useAppSelector(getIsEuTenant);
    const { value: commaSeparatedEnabledTelematicsTiles, loading: isFeatureFlagLoading } = useToggle(
        FeatureToggles.telematicstiles,
        ''
    );

    let displayedTelematics = getDisplayedTelematicsTiles(
        selectedAssetType,
        selectedBrand,
        selectedGroup,
        selectedAssetId,
        isEuTenant,
        commaSeparatedEnabledTelematicsTiles,
        isFeatureFlagLoading
    );

    const eligibleGroupTiles = getEligibleGroupTiles(
        selectedAssetType,
        selectedBrand,
        selectedGroup,
        selectedAssetId,
        isEuTenant
    );

    const onlyOtherTileDisplayed =
        displayedTelematics.length === 0 &&
        eligibleGroupTiles.length == 1 &&
        eligibleGroupTiles[0] == GROUP_TILES.other;

    const displayedGroupTiles = getDisplayedGroupTiles(eligibleGroupTiles, onlyOtherTileDisplayed);

    if (onlyOtherTileDisplayed) {
        displayedTelematics = getDisplayedTelematicsTiles(
            selectedAssetType,
            selectedBrand,
            GROUP_TILES.other,
            selectedAssetId,
            isEuTenant,
            commaSeparatedEnabledTelematicsTiles,
            isFeatureFlagLoading
        );
    }

    const { dataLayerPush } = useGoogleAnalytics();

    return (
        <div className={'display-grid grid-cols-2 grid-cols-3-sm gap-20 padding-20'}>
            {displayedTelematics.map((item, index) => (
                <Fragment key={item.displayName + index}>
                    <SelectionTile
                        show="text"
                        name={item.displayName}
                        description={item.description}
                        tileType={item.tileType}
                        animationDelay={index * 0.1}
                        onClick={() => {
                            dataLayerPush({
                                trigger: GAEventTrigger.click,
                                event: GAEventName.selectTelematics,
                                element_name: item.displayName,
                                flow_name: GAFlowName.easyOnboardingFlow,
                            });
                            setSelectedTelematic(item);
                            handleFormStepNavigation(FormStepNavigation.next);
                        }}
                    />
                </Fragment>
            ))}
            {displayedGroupTiles.map((item, index) => (
                <Fragment key={item.displayName + index}>
                    <SelectionTile
                        show="text"
                        name={item.displayName}
                        description={item.description}
                        tileType={item.tileType}
                        animationDelay={index * 0.1}
                        onClick={() => {
                            dataLayerPush({
                                trigger: GAEventTrigger.click,
                                event: GAEventName.selectTelematicsGroup,
                                element_name: item.displayName,
                                flow_name: GAFlowName.easyOnboardingFlow,
                            });
                            setSelectedGroup(item);
                        }}
                    />
                </Fragment>
            ))}
        </div>
    );
};

const isFeatureToggledTileEnabled = (
    telematicsTile: TelematicsTile,
    commaSeparatedEnabledTelematicsTiles: string | number | boolean,
    isFeatureFlagLoading: boolean
) => {
    if (typeof commaSeparatedEnabledTelematicsTiles !== 'string') {
        console.warn(`unexpected type for telematic-tile-toggle: ${typeof commaSeparatedEnabledTelematicsTiles}`);
        return false;
    }

    if (isFeatureFlagLoading) {
        return false;
    } else {
        return (
            !telematicsTile.featureToggleName ||
            commaSeparatedEnabledTelematicsTiles
                .split(',')
                .map((it) => it.trim())
                .includes(telematicsTile.featureToggleName)
        );
    }
};

const getTenantSpecificTiles = (isEuTenant: boolean | undefined) => {
    return isEuTenant ? ALL_TELEMATICS_TILES : ALL_TELEMATICS_TILES.filter((it) => it.showForNonEu);
};

const getOemTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    isEuTenant: boolean | undefined
) => {
    const isMatch = (it: typeBrandCombination): boolean => it.type == selectedAssetType && it.brand == selectedBrand;

    let tenantSpecificTelematicTiles = getTenantSpecificTiles(isEuTenant);

    return tenantSpecificTelematicTiles.filter((telematics: TelematicsTile): boolean =>
        telematics.oemFor.some(isMatch)
    );
};

const getTelematicsTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    isEuTenant: boolean | undefined
) => {
    const isMatch = (it: typeBrandCombination): boolean =>
        (it.type == selectedAssetType || selectedAssetType == undefined) &&
        (it.brand == selectedBrand || selectedBrand == undefined);

    let tenantSpecificTelematicTiles = getTenantSpecificTiles(isEuTenant);

    return tenantSpecificTelematicTiles.filter((telematics: TelematicsTile): boolean =>
        telematics.suitableFor.some(isMatch)
    );
};

const getDisplayedTelematicsTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    selectedGroup: GroupTile | undefined,
    selectedAssetId: string | undefined,
    isEuTenant: boolean | undefined,
    commaSeparatedEnabledTelematicsTiles: string | number | boolean,
    isFeatureFlagLoading: boolean
): TelematicsTile[] => {
    const eligibleTiles = getEligibleTelematicsTiles(
        selectedAssetType,
        selectedBrand,
        selectedGroup,
        selectedAssetId,
        isEuTenant
    );

    return eligibleTiles.filter((telematicsTile) =>
        isFeatureToggledTileEnabled(telematicsTile, commaSeparatedEnabledTelematicsTiles, isFeatureFlagLoading)
    );
};

export const getDisplayedGroupTiles = (
    eligibleGroupTiles: GroupTile[],
    isOnlyOtherTileDisplayed: boolean
): GroupTile[] => (isOnlyOtherTileDisplayed ? [] : eligibleGroupTiles);

export const getEligibleTelematicsTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    selectedGroup: GroupTile | undefined,
    selectedAssetId: string | undefined,
    isEuTenant: boolean | undefined
): TelematicsTile[] => {
    const isInBrandSpecificSelection =
        selectedGroup === undefined &&
        selectedAssetId === undefined &&
        selectedBrand !== undefined &&
        selectedAssetType !== undefined;

    let eligibleTiles: TelematicsTile[];

    if (isInBrandSpecificSelection) {
        eligibleTiles = getOemTiles(selectedAssetType, selectedBrand, isEuTenant);
    } else {
        eligibleTiles = getTelematicsTiles(selectedAssetType, selectedBrand, isEuTenant).filter(
            (telematics: TelematicsTile): boolean =>
                selectedAssetId !== undefined || telematics.group === selectedGroup || selectedGroup === undefined
        );
    }

    const fixedEligibleTiles = TELEMATICS_TILES_DISPLAYED_FIRST.filter((tile) => eligibleTiles.includes(tile));

    const otherEligibleTiles = eligibleTiles.filter((tile) => !TELEMATICS_TILES_DISPLAYED_FIRST.includes(tile));

    return fixedEligibleTiles.concat(orderBy(otherEligibleTiles, 'displayName', 'asc'));
};

export const getEligibleGroupTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    selectedGroup: GroupTile | undefined,
    selectedAssetId: string | undefined,
    isEuTenant: boolean | undefined
): GroupTile[] => {
    if (selectedGroup !== undefined || selectedAssetId !== undefined) {
        return [];
    }
    const isMatch = (it: typeBrandCombination): boolean => it.type == selectedAssetType && it.brand == selectedBrand;

    let tenantSpecificGroupTiles = isEuTenant ? ALL_GROUP_TILES : ALL_GROUP_TILES.filter((it) => it.showForNonEu);

    return tenantSpecificGroupTiles.filter((group: GroupTile): boolean => group.eligibleFor.some(isMatch));
};
