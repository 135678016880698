import { FunctionComponent, useEffect } from 'react';
import UnderConstruction from './UnderConstruction';
import { ASSET_TYPE_TILES } from './configuration/assetTypeTileConfig';
import { TELEMATICS_TILES } from './configuration/telematicsTileConfig';
import { useAppDispatch, useAssetsThunkDispatch } from '../../../../../configuration/setup/hooks';
import { fetchAssets, fetchDevices } from '../../../actions/assets/Assets.actions';
import {
    closeEasyOnboardingDialog,
    setIsAssetReloadImminent,
    getEasyOnboardingSelectedAssetId,
} from '../../../reducers/assets/assetsSlice';
import { trace } from '../../../../../configuration/setup/trace';
import {
    BrandSearchParamType,
    CloseIframeMessageType,
    DeviceTypeSearchParamType,
    ExistingAssetIdSearchParamType,
    GroupSearchParamType,
    SkipUpsellingSearchParamType,
    TestAppSearchParamType,
    VehicleTypeSearchParamType,
} from './types';
import IframeResizer from 'iframe-resizer-react';
import { useSelector } from 'react-redux';
import { getIsEuTenant } from '../../../../../configuration/login/loginSlice';
import { AssetTypeTile, BrandTile, GroupTile, TelematicsTile } from './configuration/types';

interface FormStepConnectorSelectionProps {
    selectedTelematic: TelematicsTile | undefined;
    selectedAssetType: AssetTypeTile | undefined;
    selectedBrand: BrandTile | undefined;
    selectedGroup: GroupTile | undefined;
}

const IFrameWrapper = (props: { iframeUrl: string }) => (
    <IframeResizer
        id={'connector-iframe-wrapper'}
        title={'connector-iframe-wrapper'}
        src={props.iframeUrl}
        className={'modal-body bg-lightest padding-0 height-75vh'}
        heightCalculationMethod={'lowestElement'}
        style={{ width: '1px', minWidth: '100%', border: '0' }}
        data-cy={'connector-iframe-wrapper'}
    />
);

const FormStepNoTelematics = () => (
    <div className="padding-20">
        <h1>No telematic selected</h1>
        <UnderConstruction />
    </div>
);
export const getIFrameUrl = (
    selectedTelematic: TelematicsTile,
    selectedBrand: BrandTile | undefined,
    selectedAssetType: AssetTypeTile | undefined,
    selectedAssetId: string | undefined,
    selectedGroup: GroupTile | undefined,
    skipUpselling: boolean
) => {
    const url = new URL(selectedTelematic.connectorUri!);
    url.searchParams.append(DeviceTypeSearchParamType, selectedTelematic.deviceType);
    if (selectedBrand) {
        url.searchParams.append(BrandSearchParamType, selectedBrand.name);
    }
    if (selectedAssetType) {
        url.searchParams.append(VehicleTypeSearchParamType, selectedAssetType.assetType);
    }
    if (selectedAssetId) {
        url.searchParams.append(ExistingAssetIdSearchParamType, selectedAssetId);
    }
    if (selectedTelematic === TELEMATICS_TILES.rioPocketDriverTest) {
        url.searchParams.append(TestAppSearchParamType, 'true');
    }
    if (selectedGroup) {
        url.searchParams.append(GroupSearchParamType, selectedGroup.name);
    }
    if (skipUpselling) {
        url.searchParams.append(SkipUpsellingSearchParamType, 'true');
    }

    // url.href returns the url parameter before hash value, so the url is build manually
    return `${url.protocol}//${url.host}${url.pathname}${url.hash}${url.search}`;
};
export const FormStepConnector: FunctionComponent<FormStepConnectorSelectionProps> = (
    props: FormStepConnectorSelectionProps
) => {
    const { selectedTelematic, selectedAssetType, selectedBrand, selectedGroup } = props;
    const appDispatch = useAppDispatch();
    const assetsThunkDispatch = useAssetsThunkDispatch();
    const selectedAssetId = useSelector(getEasyOnboardingSelectedAssetId);

    const isEuTenant = useSelector(getIsEuTenant);
    let tenantAdjustedTelematic = selectedTelematic;
    if (tenantAdjustedTelematic === undefined && !isEuTenant) {
        tenantAdjustedTelematic =
            selectedAssetType === ASSET_TYPE_TILES.trailer ? TELEMATICS_TILES.noTelematics : TELEMATICS_TILES.rioBox;
    }

    useEffect(() => {
        const handleMessageFromIFrame = (event: MessageEvent<{ type: string }>) => {
            if (event.data.type == CloseIframeMessageType) {
                trace('received CLOSE_IFRAME message. Closing the iframe now.');
                appDispatch(closeEasyOnboardingDialog());
                appDispatch(setIsAssetReloadImminent(true)); // gets reset with fetchDevices()
                setTimeout(
                    () => assetsThunkDispatch(fetchAssets()).then(() => assetsThunkDispatch(fetchDevices())),
                    2000
                );
            }
        };
        window.addEventListener('message', handleMessageFromIFrame);

        return () => window.removeEventListener('message', handleMessageFromIFrame);
    }, [appDispatch, assetsThunkDispatch]);

    if (tenantAdjustedTelematic !== undefined) {
        return (
            <IFrameWrapper
                iframeUrl={getIFrameUrl(
                    tenantAdjustedTelematic,
                    selectedBrand,
                    selectedAssetType,
                    selectedAssetId,
                    selectedGroup,
                    !isEuTenant
                )}
            />
        );
    } else {
        return <FormStepNoTelematics />;
    }
};
