import { useConfigCatClient, useFeatureFlag, User } from 'configcat-react';
import { useAppSelector } from '../setup/hooks';
import { getIdToken } from '../tokenHandling/tokenHandlingSlice';
import { trace } from '../setup/trace';

export const FeatureToggles = {
    telematicstiles: 'telematicstiles', // comma separated, e.g. webfleet,pocketDriver,scania
    MASTERDATA_ENGINE_VEHICLE: 'masterdataEngineVehicle',
    ENABLE_ASSET_DELETE: 'enableAssetDelete', // enable delete, even though contracts are active
} as const;

type FeatureTogglesT = (typeof FeatureToggles)[keyof typeof FeatureToggles];

const MOCK_FT_VALUES: Record<FeatureTogglesT, string | boolean | number> = {
    telematicstiles: 'webfleet,pocketDriver,scania',
    masterdataEngineVehicle: true,
    enableAssetDelete: false,
};

export const useToggle = (featureToggleId: FeatureTogglesT, defaultValue: string | boolean | number = false) => {
    const idToken: any = useAppSelector(getIdToken); // TODO remove any type once getIdToken returns proper type
    let userObject: User;

    if (idToken === null || idToken === undefined) {
        userObject = new User('unknown');
    } else {
        userObject = new User(idToken.sub, undefined, idToken.locale, {
            account: idToken.account,
            client: idToken.azp,
            tenant: idToken.tenant,
        });
    }
    trace(`userObject with mail ${idToken.email}`, userObject);
    const client = useConfigCatClient();
    const result = useFeatureFlag(featureToggleId, defaultValue, userObject);

    if (import.meta.env.DEV) {
        client.setOffline();
        return { value: MOCK_FT_VALUES[featureToggleId], loading: false };
    } else {
        return result;
    }
};

export const useEnableAssetDeleteToggle = () => {
    const { value } = useToggle(FeatureToggles.ENABLE_ASSET_DELETE);

    return typeof value === 'boolean' ? value : false;
};
